/*!
 * Bootstrap v5.0.0-beta3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// bootstrap components
@import "../../node_modules/bootstrap/scss/bootstrap";

// bootstrap grid
@import "../../node_modules/bootstrap/scss/bootstrap-grid";

// bootstrap reboot
@import "../../node_modules/bootstrap/scss/bootstrap-reboot";

// bootstrap utilities
@import "../../node_modules/bootstrap/scss/bootstrap-utilities";
